import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import PrintStepSection from 'styleguide/components/PrintStepSection/PrintStepSection';
import MainSection, { UploadForm } from './MainSection/MainSection';
import { User } from 'contexts/UserContextContainer/types';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import queryString from 'qs';
import { useLocation } from 'react-router';

interface Props {
  user: User;
}

function instanceOfUploadForm(object: unknown): object is UploadForm {
  return typeof object === 'object' && 'type' in object;
}

const UploadPage = ({ user }: Props) => {
  const location = useLocation();
  const query = queryString.parse(location.search, { ignoreQueryPrefix: true });
  const uploadForm: UploadForm = instanceOfUploadForm(query) ? (Object.assign(query) as UploadForm) : null;

  return (
    <>
      <TitleContainer title="Upload files" />
      <MainSection user={user} uploadForm={uploadForm} />
      <PrintStepSection />
      <Footer />
    </>
  );
};

export default UploadPage;
