import * as React from 'react';
import cn from 'classnames';
import { boolean, object, string } from 'yup';
import css from './MainSection.scss';
import Grid from 'styleguide/components/Grid/Grid';
import NeedHelpSection from 'styleguide/components/NeedHelpSection/NeedHelpSection';
import UploadAreaWithStatus from 'styleguide/components/UploadAreaWithStatus/UploadAreaWithStatus';
import { User } from 'contexts/UserContextContainer/types';
import { Field, Form, Formik } from 'formik';
import Button from 'styleguide/components/Button/Button';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import Input from 'styleguide/components/Formik/Input/Input';
import { phoneRegex } from 'utils/schema';
import Checkbox from 'styleguide/components/Formik/Checkbox/Checkbox';
import Label from 'styleguide/components/Formik/Label/Label';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import { ErrorMessage } from 'styleguide/components/forms';

export interface UploadForm {
  type?: string;
  fullName: string;
  email: string;
  phone: string;
  comments: string;
  agreeTerms: boolean;
}

interface Props {
  user: User;
  uploadForm?: UploadForm;
}

const userFormSchema = object().shape({
  fullName: string().required('Name is required'),
  email: string().email().required('Email is required'),
  phone: string().required('Phone number is required').matches(phoneRegex, 'Phone number is not valid'),
  comments: string(),
  agreeTerms: boolean().oneOf([true], 'You must agree to the terms and conditions'),
});

const MainSection = ({ user, ...props }: Props) => {
  const [hideForm, setHideForm] = React.useState(!!props.uploadForm);

  let initialUploadForm: UploadForm;
  if (props.uploadForm) {
    initialUploadForm = props.uploadForm;
  } else if (user) {
    initialUploadForm = {
      fullName: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: '',
      comments: '',
      agreeTerms: false,
    };
  } else {
    initialUploadForm = { fullName: '', email: '', phone: '', comments: '', agreeTerms: false };
  }

  const [uploadForm, setUploadForm] = React.useState<UploadForm>(initialUploadForm);

  return (
    <Grid.Container className={cn(css.container)}>
      <Grid noPadding>
        <Grid.Row className={cn(css.row)}>
          <Grid.Col sm={12} md={1} />
          <Grid.Col className={cn(css.mainContainer)} sm={12} md={6}>
            {hideForm ? (
              <UploadAreaWithStatus
                onLoading={() => undefined}
                onReady={() => undefined}
                ownerType="Mgx::ProofApproval"
                uploadForm={uploadForm}
                showButtons
              />
            ) : (
              <Formik
                initialValues={uploadForm}
                onSubmit={values => {
                  setHideForm(true);
                  setUploadForm(values);
                }}
                validationSchema={userFormSchema}
              >
                {formikProps => (
                  <Form>
                    <FieldWrapper
                      className={cn(css.field)}
                      Input={<Field name="fullName" component={Input} label="Full Name" />}
                    />
                    <FieldWrapper
                      className={cn(css.field)}
                      Input={<Field name="email" component={Input} label="Email" />}
                    />
                    <FieldWrapper
                      className={cn(css.field)}
                      Input={<Field name="phone" component={Input} label="Phone" />}
                    />
                    <FieldWrapper
                      className={cn(css.field)}
                      Input={<Field name="comments" rows={5} component={Textarea} label="Comments" />}
                    />
                    <FieldWrapper
                      className={cn(css.checkBoxContainer)}
                      Label={
                        <Label placement="bottom" normalFontWeight className={cn(css.labelText)}>
                          I understand that I still need to email my Printivity service representative after I
                          finish uploading. I understand my uploads are not accepted until I hear back from a
                          customer service representative.
                        </Label>
                      }
                      Input={<Field name="agreeTerms" component={Checkbox} />}
                    />
                    <Grid.Row>
                      <Grid.Col>
                        {!!formikProps.touched.agreeTerms && !!formikProps.errors.agreeTerms && (
                          <ErrorMessage message={formikProps.errors.agreeTerms} />
                        )}
                      </Grid.Col>
                    </Grid.Row>
                    <Button className={cn(css.submitButton)} type="submit" color="blue">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </Grid.Col>
          <Grid.Col sm={12} md={1} />
          <Grid.Col sm={12} md={3}>
            <NeedHelpSection />
          </Grid.Col>
          <Grid.Col sm={12} md={1} />
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};
export default MainSection;
