import * as React from 'react';
import cn from 'classnames';
import Grid from '../Grid/Grid';
import { IconBleeds, IconDimension, IconResolution } from '../../icons';
import css from './PrintStepSection.scss';
import { H3, H5 } from '../Heading';
import { SvgProps } from '../Svg/SvgIcon';

interface IndividualStep {
  Icon: React.ReactElement<SvgProps>;
  Heading: string;
  Description: string;
}

const Steps: IndividualStep[] = [
  {
    Icon: <IconDimension size="xxl" />,
    Heading: 'Size',
    Description:
      'Ensure the dimensions of your files match the size of each page of the project you’re creating.',
  },
  {
    Icon: <IconResolution size="xxl" />,
    Heading: 'Resolution',
    Description: 'Make sure your document files have the right resolution. You can’t go wrong with 300dpi.',
  },
  {
    Icon: <IconBleeds size="xxl" />,
    Heading: 'Bleeds',
    Description:
      'Run backgrounds or artwork to the edge of a page by making it 1/4" bigger than the page size.',
  },
  {
    Icon: <IconDimension size="xxl" />,
    Heading: 'Fonts',
    Description:
      'Flatten a copy of your project file to avoid mismatched fonts by turning them into artwork.',
  },
];

const PrintStepSection = () => (
  <Grid.Container className={cn(css.container)}>
    <Grid>
      <Grid.Row className={cn(css.row)}>
        <Grid.Col md={1} hiddenSm="up" />
        <Grid.Col sm={12} md={10}>
          <H3 className={cn(css.printStepHeading)}>
            Four simple steps to
            <br />
            print perfection
          </H3>
        </Grid.Col>
        <Grid.Col md={1} hiddenSm="up" />
      </Grid.Row>
      <Grid.Row className={cn(css.row)}>
        <Grid.Col md={1} hiddenSm="up" />
        <Grid.Col className={cn(css.stepsContainer)} sm={12} md={10}>
          <Grid.Row className={cn(css.stepsContainerRow)}>
            {Steps.map((Step, index) => (
              <Grid.Col key={`printStep${index}`} className={cn(css.step)} sm={12} md={3}>
                {Step.Icon}
                <H5 className={cn(css.stepHeading)}>{`${index + 1}. ${Step.Heading}`}</H5>
                <p className={cn(css.stepDescription)}>{Step.Description}</p>
              </Grid.Col>
            ))}
          </Grid.Row>
        </Grid.Col>
        <Grid.Col md={1} hiddenSm="up" />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);
export default PrintStepSection;
